<script>
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    const lowResolutionImage = this.block?.fields?.Media.templateId === 'Image'
      && this.$toLitiumMediaUrl(this.block.fields?.Media?.id, { maxWidth: 100 });

    return {
      highResolutionImage: null,
      imgUrl: lowResolutionImage,
      isImageDownloaded: false,
    };
  },
  mounted() {
    if (!this.isImage) {
      return;
    }

    const media = this.$refs.mediaContainer;
    const mediaQuery = window.matchMedia('(max-width: 767px)');

    this.highResolutionImage = this.$toLitiumMediaUrl(this.block.fields?.Media?.id,
      {
        ...(mediaQuery.matches
          ? { maxHeight: media.clientHeight }
          : { maxWidth: media.clientWidth }),
      });

    this.$nextTick(() => {
      const img = new Image();

      img.onload = () => {
        this.imgUrl = this.highResolutionImage;
        this.isImageDownloaded = true;
      };

      img.src = this.highResolutionImage;
    });
  },
  computed: {
    maxWidth() {
      return this.block?.fields?.MaxWidth || '1440px';
    },
    isImage() {
      return this.block?.fields?.Media.templateId === 'Image';
    },
    isVideo() {
      return this.block?.fields?.Media.templateId === 'Movie';
    },
    videoUrl() {
      return this.$toLitiumMediaUrl(this.block.fields?.Media?.id);
    },
  },
};
</script>

<template>
  <div
    class="media__container"
    :style="{'max-width' : maxWidth}"
    ref="mediaContainer"
  >
    <img
      v-if="isImage"
      :class="['media__media-content',
      {'media__image-loading' : !isImageDownloaded}]"
      :src="imgUrl"
    >
    <video
      v-if="isVideo"
      muted
      autoplay
      loop
      playsinline
      class="media__media-content"
      :src="videoUrl"
    >
    </video>
  </div>
</template>

<style>
  .media__container {
    min-height: 180px;
    width: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
  }

  .media__media-content {
    display: block;
    margin: auto;
    width: 100%;
    height: 100%;
    max-height: 90vh;
    object-fit: cover;
  }

  .media__image-loading {
    filter: blur(5px);
  }

  @media (--phone) {
    .media__media-content {
      position: absolute;
    }
  }
</style>
